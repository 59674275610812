/* service.css enhancements */

.container_search-list {
    overflow: hidden !important;
    position: relative;
    height: 100vh;
  }
  
  .container_search-list.container_service-list {
    overflow-x: auto;
  }
  
  @media (max-width: 767.98px) {
    .container_search-list.container_service-list .search-toggle_form .search_heading {
      display: block;
    }
  }
  
  .service-data-grid {
    transition: width 0.3s ease;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Side menu transition enhancements */
  .menu-item {
    transition: background-color 0.2s ease;
  }
  
  .menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Proper container nesting without extra padding */
  #mainPanel {
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  /* Fix left panel transition */
  #leftPanel {
    transition: width 0.3s ease;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* Ensure DataGrid adapts properly */
  .dx-datagrid {
    transition: width 0.3s ease;
    overflow: hidden;
  }
  
  /* Fix DataGrid container */
  .dx-datagrid-container {
    box-sizing: border-box;
  }
  
  /* Timer positioning fix */
  .refresh-timer-container {
    position: absolute;
    top: 4px;
    right: 20px;
    z-index: 1000;
  }
  
  /* Fix scrolling issues */
  .dx-scrollable-content {
    overflow: visible;
  }
  
  /* Ensure clean edges */
  .dx-datagrid-headers {
    border-top-width: 0;
  }
  
  /* Prevent unwanted spacing */
  .dx-datagrid-rowsview {
    margin-top: 0 !important;
  }